import ReactDOM from 'react-dom';
import React from 'react';
import { SocialIcon } from 'react-social-icons';
import pfp from '../imgs/pfp.png'
import stanfordEVGR from '../imgs/stanford-evgr.png'
import oval from '../imgs/oval.png'
import cabbage from '../imgs/cabbage.png'
import earth from '../imgs/earth.png'
import roman from '../imgs/roman.png'
import hoover from '../imgs/hoover.png'
import greed from '../imgs/greed.png'
import apple from '../imgs/apple.png'

export function AboutPage() {

    return (
        <div className= 'flex flex-col items-center mx-[50px] md:mx-[110px] mb-[60px] space-y-10'>
            <div className= 'flex flex-col space-y-10 md:w-[680px] md:flex-row md:space-x-10 md:space-y-0 md:items-end'>
                <img src={pfp} className='h-[360px] object-contain'/>
                <div className= 'flex flex-col space-y-10'>
                    <div>
                        <h1 className= 'text-2xl font-chivo font-black mb-3'>
                            Hi, I'm Sathvik!
                        </h1>
                        <div>
                        <h1 className= 'font-sans text-2xl'>I'm a developer and designer studying
                        <span className= 'text-2xl font-chivo font-black'> Computer Science & Statistics @ University of Illinois at Urbana-Champaign</span>. 
                        <span className= 'underline text-2xl font-chivo font-black'><a href='https://fellows.kleinerperkins.com/' target='_blank'>
                        </a></span></h1>
                        
                        </div>
                    </div>
                    <div className='space-y-3 font-sans'>
                        <div>
                            <h1 className='font-chivo text-base'>CURRENTLY LEARNING</h1>
                            <p classname='text-2xl'>Go</p>
                        </div>
                        <div>
                            <h1 className= 'font-chivo text-base'>CURRENTLY READING</h1>
                            <h1 classname= 'text-2xl'>Obstacle Is The Way by Ryan Holiday</h1>
                        </div>
                        {/* <div>
                            <h1 className='font-chivo text-base'>CURRENT FAVORITE PAINTING</h1>
                            <h2 classname='text-base'>May 1808 by Francisco de Goya</h2>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className='w-full max-w-[1000px]' >
                <div className= 'mb-4'>

                </div>
                <div className='font-sans mb-10 flex flex-col space-y-10 items-center md:items-start md:flex-row md:space-x-10 md:space-y-0 md:justify-between'>
                    <div className='flex flex-col space-y-4 leading-6 text-[18px]'>
                    <div>
                        <h1 className='font-chivo text-lg'>RELEVANT COURSEWORK</h1>
                        <p className='text-2lg'>DD2257 - Data Visualization (Figma, Python, HTML/CSS, JS)</p>
                        <p className='text-2lg'>STAT 440 - Statistical Data Management (Python, R)</p>
                        <p className='text-2lg'>CS222 Software Design (Java, Figma, JS)</p>
                        <p className='text-2lg'>CS411 - Database Systems (SQL)</p>
                        <p className='text-2lg'>CS340 - Computer Systems (C++, Python) </p>
                        <p className='text-2lg'>STAT426 - Statistical Modeling II (R)</p>
                        <p className='text-2lg'>CS225 - Data Structures & Algorithms (C++) </p>
                        <p className='text-2lg'>ECON 302 - Inter Microeconomic Theory</p>
                        <p className='text-2lg'>CS440 - ECON 203 - Economic Statistics II</p>
                        <p className='text-2lg'>CS440 - Artificial Intelligence (Python)</p>
                        <p className='text-2lg'>CS 421 - Programming Languages & Compilers (OCaml)</p>
                        <p className='text-2lg'>CS440 - Numerical Methods I (Python)</p>
                        <p className='text-2lg'>STAT 410 - Statistics and Probability II (R)</p>


                    </div>


                    </div>
                    <div className= ''>
                        <img src={oval} className='max-h-[400px] items-center object-contain'/>
                    </div>
                </div> 
                <div className= ''>
                    <h1 className= 'mb-4 font-chivo text-base py-2 border-b-[1px] border-black'>
                    @ HOME
                    </h1>
                    <div className= 'flex flex-col space-y-10 md:space-y-0 md:flex-row md:space-x-10 mb-10'>
                        <div className='space-y-3 font-sans'>
                            <div>
                                <h1 className='font-chivo text-base'>HOME</h1>
                                <p classname='text-2xl'>Chicago</p>
                            </div>
                            <div>
                                <h1 className= 'font-chivo text-base'>HOBBIES</h1>
                                <h1 classname= 'text-2xl'>Lifting, Entrepreneurship, Triathalons, Photography, Podcasts, Reading</h1>
                            </div>
                        </div>
                        <div className= 'font-sans text-xl space-y-3'>
                        <p> Throughout my whole life, I've always been interested in creating products, both physical and digital, through engineering. Outside of that, I also love photography and athletics. </p>
                        <p>As of recently, I am training for an Ironman in New York, while also working on some side projects and re-learning the piano!</p>
                        </div>
                    </div>
                    {/* <div className= 'w-full overflow-hidden'> 
                        <div className='flex w-[200%] left-[-1000px]'>
                            <img src={cabbage} className= 'h-[320px]'/>
                            <img src={cabbage} className= 'h-[320px]'/>
                            <img src={cabbage} className= 'h-[320px]'/>
                            <img src={cabbage} className= 'h-[320px]'/>
                            <img src={cabbage} className= 'h-[320px]'/>
                            <img src={cabbage} className= 'h-[320px]'/>
                        </div>
                    </div> */}

                </div>
            </div>
        </div>
    )
}