import { ProjectCard } from '../components/ProjectCard';
import spotifyTinder from '../imgs/ionis.png';
import fizzCard from '../imgs/medlaunch.png';
import carta from '../imgs/kth-ubi.png';
import voiceMessage from '../imgs/Vletics-Laptop-View.png';
import React from 'react';


export function WorkPage() {
  return (
    <div className="flex flex-col main-body items-center mb-[60px] space-y-10">
      <div className="grid grid-cols-1 gap-y-10 md:grid-cols-2 md:gap-x-20 md:gap-y-10">
        <ProjectCard
          id="1"
          color="#fffff1"
          title="Data Intern @ Ionis"
          tags={['Product', 'Software', 'Modeling']}
          company="Internship"
          img={spotifyTinder}
          projectPage="/construction"
        />
        <ProjectCard
          id="2"
          color="#1a2b57"
          title="Head of Product @ MedLaunch"
          tags={['Software & Hardware', 'Prototyping', 'Leadership']}
          company="Club"
          img={fizzCard}
          projectPage="/construction"
        />
        <ProjectCard
          id="3"
          color="#84E7D6"
          title="Urban Mobility Initiative"
          tags={['UI/UX', 'Product Strategy']}
          company="Product Strategy"
          img={carta}
          projectPage="/construction"
        />
        <ProjectCard
          id="4"
          color="#38cb82"
          title="Vletics"
          tags={['Users', 'Software', 'AI']}
          company="Startup Founder"
          img={voiceMessage}
          projectPage="/construction"
        />
      </div>
    </div>
  );
}
